import React from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { innerWidth, subFont, screen } from "../components/variables";
import ButtonUnderlined from "../components/buttons/button-underlined";
import IconArrowZigZag from "../images/svg/arrow-right-zigzag.svg";

const Wrapper = styled.div`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .header {
    .heading {
      font-weight: 900;
      line-height: 1.22;
      position: relative;
      font-size: 2rem;
      margin: 0 0 70px 0;
      @media ${screen.xxsmall} {
        font-size: 3.8rem;
        max-width: 450px;
        margin: 0;
      }
      @media ${screen.small} {
        line-height: 1.09;
        font-size: 5rem;
        max-width: 580px;
      }

      .pointer {
        position: absolute;
        top: 50px;
        right: 110px;
        @media ${screen.xxsmall} {
          top: 17px;
          right: -46px;
        }

        &__arrow {
          svg {
            width: 40px;
            transform: scaleY(-1) rotate(-5deg);
            @media ${screen.xxsmall} {
              width: 49px;
            }
          }
        }

        &__text {
          font-family: ${subFont};
          letter-spacing: 1px;
          font-size: 1.16rem;
          position: absolute;
          width: 120px;
          bottom: -31px;
          right: -119px;
          font-weight: 400;
          line-height: 1.15;
          @media ${screen.xxsmall} {
            font-size: 1.4rem;
            bottom: -30px;
            right: -142px;
          }
        }
      }
    }
  }

  .service-list {
    display: flex;
    margin: 35px 0 110px 0;
    flex-wrap: wrap;
    @media ${screen.xxsmall} {
      margin: 35px -13px 110px -13px;
    }
    @media ${screen.medium} {
      max-width: 1250px;
      margin: 35px 0 110px auto;
    }

    &__each {
      margin: 0 0 60px 0;
      display: flex;
      flex-direction: column;
      @media ${screen.xxsmall} {
        width: calc(33.33% - 26px);
        margin: 0 13px;
      }
      @media ${screen.medium} {
        margin: 0 0 0 26px;
      }

      .title {
        font-weight: 700;
        line-height: 1.4;
        margin: 16px 0 0 0;
        cursor: pointer;
        font-size: 1.25rem;
        @media ${screen.xxsmall} {
          font-size: 1.52rem;
          margin: 23px 0 0 0;
        }
        @media ${screen.small} {
          font-size: 1.55rem;
        }
      }

      .featured-img {
        cursor: pointer;
      }

      .description {
        margin: 12px 0 21px 0;
        padding: 0 18px 0 0;
        font-size: 1.02rem;
        @media ${screen.small} {
          font-size: 1.06rem;
          margin: 18px 0 38px 0;
        }
      }

      .link {
        margin: auto 0 0 0;
      }
    }
  }
`;

const ServicesPage = ({ data }) => {
  const { heading, title_tag, meta_description } = data.content.data;

  const { service_list } = data.services.data;

  const pageURL = `https://www.k9swim.com.au/services/`;

  return (
    <Layout>
      <SEO
        title={title_tag || "FAQs"}
        description={meta_description}
        url={pageURL}
      />
      <Wrapper>
        <div className="inner-wrapper">
          <header className="header">
            <h1 className="heading">
              {heading.text}
              <span className="pointer" role="presentation">
                <span className="pointer__arrow">
                  <IconArrowZigZag />
                </span>
                <span className="pointer__text">a splashing good time</span>
              </span>
            </h1>
          </header>

          <ul className="service-list">
            {service_list.map((item, i) => (
              <li
                className="service-list__each"
                key={`${i}${item.heading.text}`}
              >
                <figure
                  className="featured-img"
                  onClick={() => navigate(`/services/${item.url_slug}/`)}
                >
                  <GatsbyImage
                    image={
                      item.featured_image.thumbnails.cropped.gatsbyImageData
                    }
                    alt={item.featured_image.alt || "Feautured"}
                  />
                </figure>
                <h2
                  className="title"
                  onClick={() => navigate(`/services/${item.url_slug}/`)}
                >
                  {item.heading.text}
                </h2>
                <p className="description">{item.description}</p>
                <ButtonUnderlined
                  label="LEARN MORE"
                  to={`/services/${item.url_slug}/`}
                  className="link"
                />
              </li>
            ))}
          </ul>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ServicesPage;

export const dataQuery = graphql`
  {
    content: prismicServicesPage {
      data {
        heading {
          text
        }
        title_tag
        meta_description
      }
    }

    services: prismicHomePage {
      data {
        service_list {
          heading {
            text
          }
          description
          featured_image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          url_slug
        }
      }
    }
  }
`;
